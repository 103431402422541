import React, { useState, useEffect } from "react";
import { IReportResultTimeLines } from "../../Modals/GradeOverTime";
import { convertDateFormal1 } from "../../Utilities/dateUtilities";

interface IHead {
  data: IReportResultTimeLines[];
  reportType: string;
  startDate: string;
  headerList: any[];
}
const Header = ({ data, reportType, headerList, startDate }: IHead) => {
  const [frequency, setFrequency] = useState("Week");
  useEffect(() => {
    if (reportType === "Weekly") setFrequency("Week");
    else if (reportType === "Monthly") setFrequency("month");
  }, [reportType]);
  return (
    <thead>
      {/* <tr>
        <th style={{ minWidth: 100 }} className="cellDefault"></th>
        <th className="cellDefault"></th>
        <th className="cellDefault">
          Baseline({convertDateFormal1(startDate)})
        </th>
        {Array.isArray(data) ? (
          data.map((headItem: any, index: number) => (
            <th colSpan={2} className="cellDefault">
              {headItem.tagline}
            </th>
          ))
        ) : (
          <></>
        )}
      </tr> */}
      <tr>
        <th style={{ minWidth: 100 }} className="cellDefault"></th>
        <th className="cellDefault"></th>
        {Array.isArray(data) ? (
          data.map((headItem: any, index: number) => (
            <th colSpan={index ? 2 : 1} className="cellDefault">
              {headItem.tagline}
            </th>
          ))
        ) : (
          <></>
        )}
      </tr>
      <tr>
        {Array.isArray(headerList) ? (
          headerList.map((headItem: any, index: number) => (
            <th style={index ? {} : { minWidth: 160 }} className="cellDefault">
              {headItem.headerName(frequency)}
            </th>
          ))
        ) : (
          <></>
        )}
      </tr>
    </thead>
  );
};

export default Header;

import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import Layout from "../../General/Layout";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IReportHistoryRow } from "../../Modals/IReportHistoryRow";
import { getReportsHistoryData } from "../../Actions/getReportHistory";
import { convertDateFormal1 } from "../../Utilities/dateUtilities";
const ReportHistory = () => {
  const [reportHistoryData, setReportHistoryData] =
    useState<IReportHistoryRow[]>();
  const [loadStatus, setLoadStatus] = useState("");

  useEffect(() => {
    setLoadStatus("Loading...");
    getReportsHistoryData(setReportHistoryData, setLoadStatus);
  }, []);

  const navigate = useNavigate();

  const onViewBtnClick = (row: any) => {
    navigate(`/${row.reportType ?? "gradeovertime"}/${row.id ?? "1"}`);
  };

  return (
    <Layout isBreadCrumHidden={true}>
      <Container>
        <Row className="mb-3 justify-content-md-center" md="auto">
          <Col className="historyTableWidth">
            <Card style={{ marginBottom: 100 }}>
              <Card.Header>
                <h4>Report History</h4>
                List of all the reports generated or in process. Upon completion
                of the report's status,click 'View' to access the report.
              </Card.Header>
              <Card.Body>
                {reportHistoryData ? (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 950 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Report Type</TableCell>
                          <TableCell align="center">Baseline Date</TableCell>
                          <TableCell align="center">Start Date</TableCell>
                          <TableCell align="center">Frequency Type</TableCell>
                          <TableCell align="center">Frequency Number</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Progress (%)</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {reportHistoryData.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.reportType == "GradeOverTime"
                                ? "Grade Over Time"
                                : row.reportType == "AreaOverTime"
                                ? "Area Over Time"
                                : "School Over Time"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {convertDateFormal1(row.baseLineDate)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {convertDateFormal1(row.startDate)}
                            </TableCell>
                            <TableCell align="center">
                              {row.frequenceyType}
                            </TableCell>
                            <TableCell align="center">
                              {row.frequenceyNumber}
                            </TableCell>
                            <TableCell align="center">{row.status}</TableCell>
                            <TableCell align="center">
                              {row.progress} %
                            </TableCell>
                            {row.status === "Completed" ? (
                              <TableCell align="center">
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  onClick={() => onViewBtnClick(row)}
                                >
                                  View
                                </Button>
                              </TableCell>
                            ) : (
                              <></>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Alert className="my-5 text-center" variant={"warning"}>
                    {loadStatus}
                  </Alert>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ReportHistory;

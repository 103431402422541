import Layout from "../../General/Layout";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Button,
  Toast,
  Dropdown,
} from "react-bootstrap";
import {
  reportTypeOptions,
  freqTypeOptions,
  freqNumberOptions,
} from "../../Utilities/constants";
import { useState, useEffect } from "react";
import { IReportInputs } from "../../Modals/IReportInputs";
import {
  postGenerateReport,
  getBaselineData,
} from "../../Actions/postGenerateReport";
import { useNavigate } from "react-router-dom";
import { IBaselineFile } from "../../Modals/IBaselineFile";

const Index = () => {
  const [reportInputs, setReportInputs] = useState<IReportInputs>({
    reportType: "",
    startDate: new Date().toLocaleDateString("en-CA"),
    baseLineDate: new Date().toLocaleDateString("en-CA"),
    frequenceyType: "",
    baseLineFileName: "",
    frequenceyNumber: 1,
    requestDate: new Date().toLocaleDateString("en-CA"),
    manualBaselineType: "",
    baselineFileId: "",
    status: "",
    progress: 0,
    isBaselineUploaded: false,
    file: "",
  });

  const [baselineData, setBaselineData] = useState<IBaselineFile[]>([]);
  const [formErrors, setFormErrors] = useState<any>();
  const [postStatus, setPostStatus] = useState<any>("");
  const [show, setShow] = useState(false);
  const [futureDateError, setFutureDateError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const updateField = (e: any) => {
    setReportInputs({ ...reportInputs, [e.target.name]: e.target.value });
    //const errors = validateFields();
    //setFormErrors(errors);
  };

  const handleChangeFile = (e: any) => {
    const files: any = e.target.files;
    setReportInputs({ ...reportInputs, file: files?.length ? files[0] : "" });
  };

  const clearError = (e: any) => {
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const navigateWithDelay = (id: number) => {
    // timeout added to allow user to read the message and them move to status page (or History Page?)
    setTimeout(() => {
      navigate("/reportstatus/" + id);
    }, 2000);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (futureDateError) return;
    const errors = validateFields();

    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      console.log(reportInputs);
      postGenerateReport(
        reportInputs,
        setPostStatus,
        setShow,
        navigateWithDelay
      );
    }
  };

  const validateFields = () => {
    const newErrors: IReportInputs = {} as IReportInputs;
    const {
      reportType,
      startDate,
      frequenceyType,
      frequenceyNumber,
      isBaselineUploaded,
      file,
      manualBaselineType,
      baselineFileId,
    } = reportInputs;

    if (!reportType) newErrors.reportType = "Report Type is a required field.";
    if (!startDate) newErrors.startDate = "Start Date is a required field.";
    if (!frequenceyType)
      newErrors.frequenceyType = "Frequency Type is a required field.";
    if (!frequenceyNumber)
      newErrors.frequenceyNumber = "Frequency Number is a required field.";
    if (isBaselineUploaded) {
      if (!manualBaselineType) newErrors.file = "Please select a baseline type";
      else if (manualBaselineType == "new" && !file)
        newErrors.file = "Please select a baseline file";
      else if (manualBaselineType == "existing" && !baselineFileId)
        newErrors.baselineFileId = "Please select a report";
    }
    return newErrors;
  };

  useEffect(() => {
    getBaselineData(setBaselineData);
  }, []);

  useEffect(() => {
    console.log("RH", baselineData);
  }, [baselineData]);

  useEffect(() => {
    if (
      reportInputs.startDate &&
      reportInputs.frequenceyType &&
      reportInputs.frequenceyNumber
    ) {
      const date = new Date(reportInputs.startDate);
      let month = date.getMonth();
      let day = date.getDate();
      let year = date.getFullYear();
      const frequency = parseInt(
        reportInputs.frequenceyNumber.toLocaleString()
      );
      let newDate;
      const currentDate = new Date();
      if (reportInputs.frequenceyType === "Monthly") {
        month = month + frequency;
        if (month > 11) {
          month -= 12;
          year += 1;
        }
        newDate = new Date(`${month + 1}/${day}/${year}`);
      } else {
        let days = frequency * 7;
        newDate = new Date(date.valueOf() + days * 24 * 60 * 60000);
      }
      setFutureDateError(newDate > currentDate ? true : false);
    } else {
      //setFutureDateError(false);
      //const errors = validateFields();
      //const newErrors: IReportInputs = {} as IReportInputs;
      //setFormErrors(newErrors);
    }
  }, [reportInputs]);

  return (
    <Layout isBreadCrumHidden={true}>
      <Container>
        <Row className="mb-3 justify-content-md-center" md="auto">
          <Col className="formWidth">
            <Card>
              <Card.Header>
                <h4>Report</h4>
                Please select inputs for the required report and click generate
                report button. Report will be generated and added to the history
                page for access.
              </Card.Header>

              <Card.Body>
                <Form>
                  {/* REPORT TYPE */}
                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-md-center"
                  >
                    <Form.Label column sm="3">
                      Report Type
                    </Form.Label>

                    <Col sm="5">
                      <Form.Select
                        aria-label="Report Type Select"
                        required
                        name="reportType"
                        value={reportInputs.reportType}
                        onChange={(e) => updateField(e)}
                        onFocus={clearError}
                        isInvalid={!!formErrors?.reportType}
                      >
                        <option value="">Select Report</option>
                        {reportTypeOptions.map((repTypeOption) => (
                          <option
                            key={repTypeOption.value}
                            value={repTypeOption.value}
                          >
                            {repTypeOption.name}
                          </option>
                        ))}
                      </Form.Select>

                      <Form.Control.Feedback type="invalid">
                        {formErrors?.reportType}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {/* Baseline DATE */}
                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-md-center"
                  >
                    <Form.Label column sm="3">
                      Baseline Date
                    </Form.Label>

                    <Col sm="5">
                      <Form.Control
                        type="date"
                        required
                        name="baseLineDate"
                        onFocus={clearError}
                        value={reportInputs.baseLineDate}
                        onChange={(e) => updateField(e)}
                      />
                    </Col>
                  </Form.Group>

                  {/* START DATE */}
                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-md-center"
                  >
                    <Form.Label column sm="3">
                      Start Date
                    </Form.Label>

                    <Col sm="5">
                      <Form.Control
                        type="date"
                        required
                        name="startDate"
                        value={reportInputs.startDate}
                        onFocus={clearError}
                        onChange={(e) => updateField(e)}
                        isInvalid={!!formErrors?.startDate || futureDateError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors?.startDate}
                        {futureDateError
                          ? "Please select a date in the past"
                          : ""}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {/* FREQUENCY TYPE */}
                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-md-center"
                  >
                    <Form.Label column sm="3">
                      Frequency Type
                    </Form.Label>

                    <Col sm="5">
                      <Form.Select
                        aria-label="Report Type Select"
                        name="frequenceyType"
                        value={reportInputs.frequenceyType}
                        onFocus={clearError}
                        required
                        onChange={(e) => updateField(e)}
                        isInvalid={!!formErrors?.frequenceyType}
                      >
                        <option value="">Select Frequency Type</option>
                        {freqTypeOptions.map((freqTypeOption) => (
                          <option
                            key={freqTypeOption.value}
                            value={freqTypeOption.value}
                          >
                            {freqTypeOption.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formErrors?.frequenceyType}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {/* FREQUENCY NUMBER */}
                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-md-center"
                  >
                    <Form.Label column sm="3">
                      Frequency Number
                    </Form.Label>

                    <Col sm="5">
                      <Form.Select
                        aria-label="Frequency Number Select"
                        name="frequenceyNumber"
                        onFocus={clearError}
                        value={reportInputs.frequenceyNumber}
                        required
                        onChange={(e) => updateField(e)}
                        isInvalid={!!formErrors?.frequenceyNumber}
                      >
                        <option value="">Select Frequency Number</option>
                        {freqNumberOptions.map((freqNumOption) => (
                          <option
                            key={freqNumOption.value}
                            value={freqNumOption.value}
                          >
                            {freqNumOption.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formErrors?.frequenceyNumber}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {/* Manual baseline */}
                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-md-center"
                  >
                    <Form.Label column sm="3">
                      Manual baseline
                    </Form.Label>

                    <Col sm="5">
                      <Form.Check
                        className="mt-2"
                        type="checkbox"
                        onFocus={clearError}
                        name="isBaselineUploaded"
                        onChange={(e) =>
                          setReportInputs({
                            ...reportInputs,
                            [e.target.name]: e.target.checked,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                  {reportInputs.isBaselineUploaded ? (
                    <>
                      <Form.Group
                        as={Row}
                        className="mb-3 justify-content-md-center"
                      >
                        <Form.Label column sm="3">
                          Select Existing
                        </Form.Label>
                        <Col sm="5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="manualBaselineType"
                            id="manualBaselineType1"
                            onClick={(e) =>
                              setReportInputs({
                                ...reportInputs,
                                manualBaselineType: "existing",
                              })
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3 justify-content-md-center"
                      >
                        <Form.Label column sm="3">
                          Upload New Baseline
                        </Form.Label>
                        <Col sm="5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="manualBaselineType"
                            id="manualBaselineType2"
                            onClick={(e) =>
                              setReportInputs({
                                ...reportInputs,
                                manualBaselineType: "new",
                              })
                            }
                          />
                        </Col>
                        <Form.Control.Feedback type="invalid">
                          {formErrors?.frequenceyNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* BASEINE FILE */}
                  {reportInputs.isBaselineUploaded &&
                  reportInputs.manualBaselineType === "new" ? (
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-md-center"
                    >
                      <Form.Label column sm="3">
                        Baseline file
                      </Form.Label>

                      <Col sm="5">
                        <Form.Control
                          type="file"
                          onFocus={clearError}
                          name="file"
                          onChange={handleChangeFile}
                          isInvalid={!!formErrors?.file}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formErrors?.file}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  ) : reportInputs.isBaselineUploaded &&
                    reportInputs.manualBaselineType === "existing" ? (
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-md-center"
                    >
                      <Form.Label column sm="3">
                        Existing baselines
                      </Form.Label>

                      <Col sm="5">
                        <Form.Select
                          aria-label="Select report"
                          name="baselineFileId"
                          onFocus={clearError}
                          value={reportInputs.baselineFileId}
                          required
                          onChange={(e) => updateField(e)}
                          isInvalid={!!formErrors?.baselineFileId}
                        >
                          <option value="">Select report</option>
                          {baselineData.map((baseline) => (
                            <option
                              key={baseline.id}
                              value={baseline.id.toString()}
                              style={{ overflow: "hidden" }}
                            >
                              {baseline.baseLineFileName}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {formErrors?.baselineFileId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                  {/* SUBMIT BUTTON */}
                  <Row className="mb-3 justify-content-md-center">
                    <Col sm={6} style={{ textAlign: "center" }}>
                      <Button type="submit" onClick={(e) => handleSubmit(e)}>
                        Generate Report
                      </Button>
                    </Col>
                  </Row>
                </Form>

                <Row className="mb-3 justify-content-md-center">
                  <Col sm={6}>
                    <Toast
                      onClose={() => setShow(false)}
                      show={show}
                      delay={postStatus["error"] ? 10000 : 3000}
                      autohide
                      bg={postStatus["error"] ? "danger" : "success"}
                    >
                      <Toast.Header>Server Message</Toast.Header>
                      <Toast.Body>
                        <strong>{postStatus["msg"]}</strong>
                      </Toast.Body>
                    </Toast>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Index;

import React from "react";
import { IgroupTotals } from "../../Modals/GradeOverTime";

interface IgroupTotalsComponent {
  groupTotals: IgroupTotals;
}

const GroupTotalBar = ({ groupTotals }: IgroupTotalsComponent) => {
  return (
    <>
      <td style={{ textAlign: "center" }} colSpan={1}>
        Total
      </td>
      <td style={{ textAlign: "center" }} colSpan={1}>
        {groupTotals?.BaselineNumber}
      </td>
      {groupTotals?.IncreaseSinceBaseLine?.map((increase) => (
        <>
          <td style={{ textAlign: "center" }}>{increase.Total}</td>
          <td style={{ textAlign: "center" }}>
            {increase.PercentageIncrease + "%"}
          </td>
        </>
      ))}
    </>
  );
};

export default GroupTotalBar;

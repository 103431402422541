export const msalConfig: any = {
    auth: {
      clientId: process.env.REACT_APP_clientId,
      authority: process.env.REACT_APP_authority,
      redirectUri: process.env.REACT_APP_redirectUri,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest: any = {
    scopes: [process.env.REACT_APP_scopes],
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  };
  
  // export const msalConfig = {
  //   auth: {
  //     clientId: "e8761e5f-43d5-4739-9d7c-905cbed91557",
  //     authority:
  //       "https://login.microsoftonline.com/0465e26c-5c7f-439b-9524-c09f720a9310", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
  //     redirectUri: "http://localhost:4100",
  //   },
  //   cache: {
  //     cacheLocation: "sessionStorage", // This configures where your cache will be stored
  //     storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  //   },
  // };
  
  // // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  // export const loginRequest = {
  //   scopes: ["api://e8761e5f-43d5-4739-9d7c-905cbed91557/test"],
  // };
  
  // // Add the endpoints here for Microsoft Graph API services you'd like to use.
  // export const graphConfig = {
  //   graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  // };
  
import interceptor from "../Utilities/interceptor";
import {
  startGenerateReport,
  startGenerateReportWithFile,
  BaselineFiles,
} from "../Utilities/baseUrl";
import { showLoader, hideLoader } from "../Utilities/loader";
import { IReportInputs } from "../Modals/IReportInputs";

const generateReportWithoutManualBaseline = async (
  reportInputs: IReportInputs
) => {
  let temp: any = { ...reportInputs };
  //delete temp.isBaselineUploaded;
  delete temp.file;
  delete temp.manualBaselineType;
  if (reportInputs.manualBaselineType !== "existing")
    delete temp.baselineFileId;
  return await interceptor.post(startGenerateReport, temp);
};

const generateReportWithManualBaseline = async (
  reportInputs: IReportInputs
) => {
  const { data } = await generateReportWithoutManualBaseline(reportInputs);
  const formData = new FormData();
  formData.append("file", reportInputs.file);
  await interceptor.put(startGenerateReportWithFile + data?.id, formData);
  return { data };
};

export const postGenerateReport = async (
  reportInputs: IReportInputs,
  setPostStatus: any,
  showMessage: any,
  handleNavigateWithDelay: any
) => {
  try {
    showLoader();
    debugger;
    const { data } =
      reportInputs.isBaselineUploaded &&
      reportInputs.manualBaselineType == "new"
        ? await generateReportWithManualBaseline(reportInputs)
        : await generateReportWithoutManualBaseline(reportInputs);
    console.log(data);
    if (!data) {
      throw new Error("Data not found");
    }
    setPostStatus({
      error: "",
      msg: "Report generation Initited.",
    });
    showMessage(true);
    hideLoader();
    handleNavigateWithDelay(data.id);
  } catch (e) {
    setPostStatus({
      error: "error",
      msg: "Server Error, Please contact support.",
    });
    showMessage(true);
    hideLoader();
    console.log(e);
  }
};

export const getBaselineData = async (setBaselineData: any) => {
  try {
    const { data } = await interceptor.get(BaselineFiles);
    if (!data) {
      throw new Error("Data not found");
    }
    setBaselineData(data);
  } catch (e) {
    console.log(e);
  }
};

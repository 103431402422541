import React, { useState, useEffect } from "react";
import Layout from "../../General/Layout";
import Grid from "@mui/material/Grid";
import BasicCard from "../../General/Cards/BasicCard";
import { tableMap } from "./map";
import TabWrapper from "../../General/Tabs/TabWrapperForSchool";
import {
  IReportResultTimeLines,
  IStudentTypesSOT,
} from "../../Modals/GradeOverTime";
import { searchReportForSchools } from "../../Actions/generalActions";
import { useParams } from "react-router-dom";
import DownloadAsExcel from "../../General/Buttons/DownloadAsExcel";
import { downloadReport } from "../../Actions/downloadReportAction";

const Index = () => {
  const [tableData, setTableData] = React.useState<IStudentTypesSOT>();
  const [reportData, setReportData] = React.useState<any>();
  const [isExcelLoaded, setIsExcelLoaded] = React.useState(false);
  const [schoolCount, setSchoolCount] = React.useState({
    Enrolled: { total: 0, loaded: 0 },
    Preregistered: { total: 0, loaded: 0 },
    TotalEnrolled: { total: 0, loaded: 0 },
  });
  const [loadedSchoolCount, setloadedSchoolCount] = React.useState<number>();
  const [tableHeaderData, setHeaderTableData] =
    React.useState<IReportResultTimeLines[]>();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      searchReportForSchools(
        id,
        setTableData,
        setHeaderTableData,
        setReportData,
        setIsExcelLoaded,
        setSchoolCount,
        tableData
      );
    }
  }, [id]);

  return (
    <Layout isBreadCrumHidden={true}>
      <Grid lg={12}>
        {tableData ? (
          <BasicCard heading="School Over Time">
            {isExcelLoaded ? (
              <DownloadAsExcel
                primaryFunction={() =>
                  downloadReport(reportData.id, "School Over Time Report.xlsx")
                }
              />
            ) : (
              <h5 className="m-2 mt-4">Preparing excel file.</h5>
            )}
            <p className="mx-2 my-1 mt-2">{`All students: ${
              schoolCount.TotalEnrolled.loaded ?? 0
            } out of ${schoolCount.TotalEnrolled.total ?? 0} loaded`}</p>
            <p className="mx-2 my-1">{`Pre registered: ${
              schoolCount.Preregistered.loaded ?? 0
            } out of ${schoolCount.Preregistered.total ?? 0} loaded`}</p>
            <p className="mx-2 my-1 mb-2">{`Current enrolled: ${
              schoolCount.Enrolled.loaded ?? 0
            } out of ${schoolCount.Enrolled.total ?? 0} loaded`}</p>
            <TabWrapper
              tableMap={tableMap.slice(0, 3 + reportData.frequenceyNumber * 2)}
              tableData={tableData}
              downloadAsExcel="Download as Excel"
              tableHeaderData={tableHeaderData}
              reportType={reportData.frequenceyType}
              startDate={reportData.startDate}
            />
          </BasicCard>
        ) : (
          <></>
        )}
      </Grid>
    </Layout>
  );
};

export default Index;

export const convertDateFormal1 = (date: string) => {
  try {
    if (!date) return "";
    let newDate = date.split("T");
    const dateArr = newDate[0].split("-");
    return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
  } catch (e) {
    console.log(e);
    return date;
  }
};

import React from "react";
import { ICodes } from "../../Modals/GradeOverTime";

interface ICommonFields {
  codes: ICodes;
  codeIndex: number;
}

const CommonFields = ({ codes, codeIndex }: ICommonFields) => {
  return (
    <>
      <tr key={codes.Code + codeIndex}>
        <td className="cellDefault"></td>
        <td className="cellDefault">{codes.Code}</td>
        <td className="cellDefault">{codes.BaseLines.BaselineNumber}</td>
        {codes.BaseLines.IncreaseSinceBaseLine.map((week) => (
          <>
            <td className="cellDefault">{week.Total}</td>
            <td className="cellDefault">{week.PercentageIncrease + "%"}</td>
          </>
        ))}
      </tr>
    </>
  );
};

export default CommonFields;

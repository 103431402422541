import React, { useState, useEffect } from "react";
import {
  ISchoolOverTime,
  IReportResultTimeLines,
} from "../../Modals/GradeOverTime";
import Table from "react-bootstrap/Table";
import * as XLSX from "xlsx";
import ToggleSwitch from "./ToggleSwitch";
import CommonFields from "./CommonFields";
import TableHeader from "./Header";

interface ITableComponent {
  tableMap: any;
  tableData: ISchoolOverTime[];
  downloadAsExcel?: any;
  fileName?: string;
  type?: XLSX.BookType;
  tab?: any;
  tableHeaderData?: IReportResultTimeLines[];
  reportType: string;
  startDate: string;
}

const Treeview = ({
  tableMap,
  tableData,
  tableHeaderData,
  reportType,
  startDate,
}: ITableComponent) => {
  const [tableDataMirror, settableDataMirror] = useState<ISchoolOverTime[]>([]);
  const [transformedTableData, setTransformedTableData] = useState<any[]>();

  const toggleSchools = (areaIndex: number) => {
    let temp = tableDataMirror;
    if (temp[areaIndex].Schools?.length) temp[areaIndex].Schools = [];
    else temp[areaIndex].Schools = tableData[areaIndex].Schools;
    settableDataMirror(JSON.parse(JSON.stringify(temp)));
  };

  const toggleGrades = (areaIndex: number, schoolIndex: number) => {
    let temp = tableDataMirror;
    if (temp[areaIndex].Schools[schoolIndex].Grades?.length)
      temp[areaIndex].Schools[schoolIndex].Grades = [];
    else
      temp[areaIndex].Schools[schoolIndex].Grades =
        tableData[areaIndex].Schools[schoolIndex].Grades;
    settableDataMirror(JSON.parse(JSON.stringify(temp)));
  };

  const toggleBaseLines = (
    areaIndex: number,
    schoolIndex: number,
    gradeIndex: number
  ) => {
    let temp = tableDataMirror;
    if (temp[areaIndex].Schools[schoolIndex].Grades[gradeIndex].Codes?.length)
      temp[areaIndex].Schools[schoolIndex].Grades[gradeIndex].Codes = [];
    else
      temp[areaIndex].Schools[schoolIndex].Grades[gradeIndex].Codes =
        tableData[areaIndex].Schools[schoolIndex].Grades[gradeIndex].Codes;
    settableDataMirror(JSON.parse(JSON.stringify(temp)));
  };

  useEffect(() => {
    if (tableData) {
      settableDataMirror(JSON.parse(JSON.stringify(tableData)));
    }
  }, [tableData]);
  return (
    <Table>
      {tableHeaderData ? (
        <TableHeader
          data={tableHeaderData}
          reportType={reportType}
          headerList={tableMap}
          startDate={startDate}
        />
      ) : (
        <></>
      )}
      <tbody>
        {tableDataMirror.map((schoolOverTime, SOTIndex) =>
          schoolOverTime.Schools?.length ? (
            schoolOverTime.Schools.map((schoolData, schoolDataIndex) =>
              schoolData.Grades?.length ? (
                schoolData.Grades.map((gradeData, gradeDataIndex) =>
                  gradeData.Codes?.length ? (
                    gradeData.Codes.map((Code, codeIndex) => (
                      <>
                        {" "}
                        {schoolDataIndex === 0 &&
                        gradeDataIndex === 0 &&
                        codeIndex === 0 ? (
                          <ToggleSwitch
                            clickFunction={() => toggleSchools(SOTIndex)}
                            name={"Area " + schoolOverTime.Area}
                            level={1}
                            iconType="19"
                            groupTotals={schoolOverTime.GroupTotals}
                          />
                        ) : (
                          <></>
                        )}
                        {gradeDataIndex === 0 && codeIndex === 0 ? (
                          <ToggleSwitch
                            clickFunction={() =>
                              toggleGrades(SOTIndex, schoolDataIndex)
                            }
                            name={schoolData.SchoolName}
                            level={2}
                            iconType="19"
                            groupTotals={schoolData.GroupTotals}
                          />
                        ) : (
                          <></>
                        )}
                        {codeIndex === 0 ? (
                          <ToggleSwitch
                            clickFunction={() =>
                              toggleBaseLines(
                                SOTIndex,
                                schoolDataIndex,
                                gradeDataIndex
                              )
                            }
                            name={"Grade " + gradeData.Grade}
                            level={3}
                            iconType="19"
                            groupTotals={gradeData.GroupTotals}
                          />
                        ) : (
                          <></>
                        )}
                        <CommonFields codes={Code} codeIndex={codeIndex} />
                      </>
                    ))
                  ) : (
                    <>
                      {gradeDataIndex === 0 && schoolDataIndex === 0 ? (
                        <ToggleSwitch
                          clickFunction={() => toggleSchools(SOTIndex)}
                          name={"Area " + schoolOverTime.Area}
                          level={1}
                          iconType="19"
                          groupTotals={schoolOverTime.GroupTotals}
                        />
                      ) : (
                        <></>
                      )}
                      {gradeDataIndex === 0 ? (
                        <ToggleSwitch
                          clickFunction={() =>
                            toggleGrades(SOTIndex, schoolDataIndex)
                          }
                          name={schoolData.SchoolName}
                          level={2}
                          iconType="19"
                          groupTotals={schoolData.GroupTotals}
                        />
                      ) : (
                        <></>
                      )}
                      <ToggleSwitch
                        clickFunction={() =>
                          toggleBaseLines(
                            SOTIndex,
                            schoolDataIndex,
                            gradeDataIndex
                          )
                        }
                        name={"Grade " + gradeData.Grade}
                        level={3}
                        iconType="20"
                        groupTotals={gradeData.GroupTotals}
                      />
                    </>
                  )
                )
              ) : (
                <>
                  {schoolDataIndex === 0 ? (
                    <ToggleSwitch
                      clickFunction={() => toggleSchools(SOTIndex)}
                      name={"Area " + schoolOverTime.Area}
                      level={1}
                      iconType="19"
                      groupTotals={schoolOverTime.GroupTotals}
                    />
                  ) : (
                    <></>
                  )}
                  <ToggleSwitch
                    clickFunction={() =>
                      toggleGrades(SOTIndex, schoolDataIndex)
                    }
                    name={schoolData.SchoolName}
                    level={2}
                    isLoaded={Boolean(
                      tableData?.[SOTIndex]?.Schools?.[schoolDataIndex]?.Grades
                        ?.length
                    )}
                    isSchool={true}
                    iconType="20"
                    groupTotals={schoolData.GroupTotals}
                  />
                </>
              )
            )
          ) : (
            <ToggleSwitch
              clickFunction={() => toggleSchools(SOTIndex)}
              name={"Area " + schoolOverTime.Area}
              level={1}
              iconType="20"
              groupTotals={schoolOverTime.GroupTotals}
            />
          )
        )}
      </tbody>
    </Table>
  );
};

export default Treeview;

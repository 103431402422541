import React, { useState, useEffect } from "react";
import {
  IAreaOverTime,
  IReportResultTimeLines,
} from "../../Modals/GradeOverTime";
import Table from "react-bootstrap/Table";
import * as XLSX from "xlsx";
import ToggleSwitch from "./ToggleSwitch";
import CommonFields from "./CommonFields";
import TableHeader from "./Header";

interface ITableComponent {
  tableMap: any;
  tableData: IAreaOverTime[];
  downloadAsExcel?: any;
  fileName?: string;
  type?: XLSX.BookType;
  tab?: any;
  tableHeaderData?: IReportResultTimeLines[];
  reportType: string;
  startDate: string;
}

const Treeview = ({
  tableMap,
  tableData,
  tableHeaderData,
  reportType,
  startDate,
}: ITableComponent) => {
  const [tableDataMirror, settableDataMirror] = useState<IAreaOverTime[]>([]);
  const [transformedTableData, setTransformedTableData] = useState<any[]>();

  const toggleGrades = (areaIndex: number) => {
    let temp = tableDataMirror;
    if (temp[areaIndex].Grades.length) temp[areaIndex].Grades = [];
    else temp[areaIndex].Grades = tableData[areaIndex].Grades;
    settableDataMirror(JSON.parse(JSON.stringify(temp)));
  };

  const toggleBaseLines = (areaIndex: number, gradeIndex: number) => {
    let temp = tableDataMirror;
    if (temp[areaIndex].Grades[gradeIndex].Codes.length)
      temp[areaIndex].Grades[gradeIndex].Codes = [];
    else
      temp[areaIndex].Grades[gradeIndex].Codes =
        tableData[areaIndex].Grades[gradeIndex].Codes;
    settableDataMirror(JSON.parse(JSON.stringify(temp)));
  };

  useEffect(() => {
    if (tableData) {
      settableDataMirror(JSON.parse(JSON.stringify(tableData)));
    }
  }, [tableData]);

  return (
    <>
      <Table>
        {tableHeaderData ? (
          <TableHeader
            data={tableHeaderData}
            reportType={reportType}
            headerList={tableMap}
            startDate={startDate}
          />
        ) : (
          <></>
        )}
        <tbody>
          {tableDataMirror.map((areaOverTime, areaIndex) =>
            areaOverTime.Grades.length ? (
              areaOverTime.Grades.map((gradeOverTime, gradeIndex) =>
                gradeOverTime.Codes.length ? (
                  gradeOverTime.Codes.map((Code, codeIndex) => (
                    <>
                      {gradeIndex === 0 && codeIndex === 0 ? (
                        <ToggleSwitch
                          clickFunction={() => toggleGrades(areaIndex)}
                          name={"Area " + areaOverTime.Area}
                          level={1}
                          iconType="19"
                          groupTotals={areaOverTime.GroupTotals}
                        />
                      ) : (
                        <></>
                      )}
                      {codeIndex === 0 ? (
                        <ToggleSwitch
                          clickFunction={() =>
                            toggleBaseLines(areaIndex, gradeIndex)
                          }
                          name={"Grade " + gradeOverTime.Grade}
                          level={2}
                          iconType="19"
                          groupTotals={gradeOverTime.GroupTotals}
                        />
                      ) : (
                        <></>
                      )}
                      <CommonFields codes={Code} codeIndex={codeIndex} />
                    </>
                  ))
                ) : (
                  <>
                    {gradeIndex === 0 ? (
                      <ToggleSwitch
                        clickFunction={() => toggleGrades(areaIndex)}
                        name={"Area " + areaOverTime.Area}
                        level={1}
                        iconType="19"
                        groupTotals={areaOverTime.GroupTotals}
                      />
                    ) : (
                      <></>
                    )}
                    <ToggleSwitch
                      clickFunction={() =>
                        toggleBaseLines(areaIndex, gradeIndex)
                      }
                      name={"Grade " + gradeOverTime.Grade}
                      level={2}
                      iconType="20"
                      groupTotals={gradeOverTime.GroupTotals}
                    />
                  </>
                )
              )
            ) : (
              <>
                <ToggleSwitch
                  clickFunction={() => toggleGrades(areaIndex)}
                  name={"Area " + areaOverTime.Area}
                  level={1}
                  iconType="20"
                  groupTotals={areaOverTime.GroupTotals}
                />
              </>
            )
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Treeview;

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const DownloadAsExcel = ({ primaryFunction }: any) => {
  return (
    <Row>
      <Col>
        <Button
          className="m-3"
          style={{ float: "right" }}
          onClick={primaryFunction}
        >
          Download As Excel
        </Button>
      </Col>
    </Row>
  );
};

export default DownloadAsExcel;

export const DATE: string = "date";
export const TEXT: string = "text";
export const NUMBER: string = "number";
export const DROPDOWN: string = "dropdown";

// HEADER OPTIONS
export const headerLinks = [
  { label: "Home", link: "/", iconNo: "home" },
  { label: "Report History", link: "/reporthistory", iconNo: "history" },
];

export const tabOptions = [
  "All Students",
  "Pre-registered",
  "Current Enrolled",
];

export const tabKeys = ["TotalEnrolled", "Preregistered", "Enrolled"];

export const UserProfileLocalStorage = "UserProfileLocalStorage";
export const TokenSessionStorage = "TokenSessionStorage";

export const reportTypeOptions = [
  { name: "Grade Over Time", value: "GradeOverTime" },
  { name: "School Over Time", value: "SchoolOverTime" },
  { name: "Area Over Time", value: "AreaOverTime" },
];

export const freqTypeOptions = [
  { name: "Weekly", value: "Weekly" },
  { name: "Monthly", value: "Monthly" },
];

export const freqNumberOptions = [
  { name: "1", value: 1 },
  { name: "2", value: 2 },
  { name: "3", value: 3 },
  { name: "4", value: 4 },
  { name: "5", value: 5 },
  { name: "6", value: 6 },
  { name: "7", value: 7 },
  { name: "8", value: 8 },
  { name: "9", value: 9 },
  { name: "10", value: 10 },
  { name: "11", value: 11 },
  { name: "12", value: 12 },
];

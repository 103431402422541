import React from "react";
import { Container, Card } from "react-bootstrap";

const BasicCard = ({ children, heading }: any) => {
  return (
    <Container className="mb-2">
      <Card>
        {heading ? (
          <Card.Header className="bgBlue50 colorNavy">{heading}</Card.Header>
        ) : (
          <></>
        )}
        {children}
      </Card>
    </Container>
  );
};

export default BasicCard;

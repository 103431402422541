import interceptor from "../Utilities/interceptor";
import { reportHistory } from "../Utilities/baseUrl";
import { showLoader, hideLoader } from "../Utilities/loader";

export const getReportsHistoryData = async (
  setReportHistoryData: any,
  setLoadStatus: any
) => {
  try {
    showLoader();
    console.log(reportHistory);
    const { data } = await interceptor.get(reportHistory);
    if (!data) {
      throw new Error("Data not found");
    }
    setReportHistoryData(data);
    console.log(data);

    hideLoader();
  } catch (e) {
    hideLoader();
    setLoadStatus("Server Error. Please contact support.");
    console.log(e);
  }
};

export const getReportHistory = async (
  reportId: string,
  setReportHistoryData: any
) => {
  try {
    showLoader();
    console.log(reportHistory);
    const { data } = await interceptor.get(reportHistory + "/" + reportId);
    if (!data) {
      throw new Error("Data not found");
    }
    setReportHistoryData(data);
    console.log(data);

    hideLoader();
  } catch (e) {
    hideLoader();
    console.log(e);
  }
};

import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  Navbar,
  Breadcrumb,
  NavDropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  headerLinks,
  UserProfileLocalStorage,
} from "../../Utilities/constants";
import IconDrawer from "../Icons";
import { useMsal } from "@azure/msal-react";

const Header = ({ breadcrumList, isOptionsHidden, isBreadCrumHidden }: any) => {
  // GETTING USER INFO USING MSAL
  const { instance, accounts } = useMsal();
  const [userInfo, setuserInfo] = useState<any>();

  useEffect(() => {
    if (accounts.length) {
      setuserInfo(accounts[0]);
    }
  }, [accounts]);

  // LOGOUT FUNCTION
  const handleLogout = (logoutType: string) => {
    if (logoutType === "redirect") {
      localStorage.removeItem(UserProfileLocalStorage);
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  const navigation = useNavigate();
  const handleGoToScreen = (link: any) => {
    navigation(link);
  };
  return (
    <>
      <Navbar className="px-2 bgBlueHead colorWhite">
        <Nav.Link onClick={() => navigation("/")}>
          <img src="/CBELogo.png" alt="Logo" height="30px" width="98px" />
          <h4 className="m-2" style={{ display: "inline-block" }}>
            Enrolment Report
          </h4>
        </Nav.Link>
      </Navbar>
      <Navbar bg="light" variant="light">
        <Container>
          <Nav className="me-auto">
            {/* HIDING HEADER OPTIONS FOR LOGIN PAGE */}
            {isOptionsHidden ? (
              <></>
            ) : (
              headerLinks.map((option: any, index) => (
                <Nav.Link
                  key={index}
                  onClick={() => {
                    if (option.label === "Back to StarsChoices") {
                      var StarsChoicesURL =
                        process.env.REACT_APP_StarsChoices1Url;
                      if (StarsChoicesURL) {
                        window.location.replace(StarsChoicesURL);
                      }
                    } else if (option.label === "Help") {
                      window.location.replace("/help.pdf");
                    } else {
                      navigation(option.link);
                    }
                  }}
                  className="colorNavy"
                >
                  <IconDrawer type={option.iconNo} className="mx-1 mb-1 fs-6" />
                  {option.label}
                </Nav.Link>
              ))
            )}
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            {/* IF USERINFO IS PRESENT DISPLAY DROPDOWN */}
            {userInfo?.name ? (
              <>
                <NavDropdown
                  title={
                    <>
                      <IconDrawer type="7" className="colorNavy fs-5 mb-2" />
                      <span className="colorNavy fs-0 mx-2">
                        {userInfo.name}
                      </span>
                    </>
                  }
                >
                  <NavDropdown.Item>Roles</NavDropdown.Item>
                  <NavDropdown.Item>
                    <IconDrawer type="9" className="mx-2" />
                    <i>
                      {userInfo?.idTokenClaims?.roles?.length
                        ? userInfo?.idTokenClaims.roles[0]
                        : "Guest"}
                    </i>
                  </NavDropdown.Item>
                  <hr></hr>
                  <NavDropdown.Item onClick={() => handleLogout("redirect")}>
                    <IconDrawer type="10" /> Sign Out
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <>
                <Nav.Link onClick={() => navigation("/")} className="colorNavy">
                  <IconDrawer type="7" className="mx-1 mb-1 fs-6" />
                  Login
                </Nav.Link>
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* HIDING BREADCRUMB FOR LOGIN PAGE */}
      {isBreadCrumHidden ? (
        <></>
      ) : (
        <Container>
          <Breadcrumb aria-label="breadcrumb" className="my-3 fs-6">
            <Breadcrumb.Item active onClick={() => handleGoToScreen("/home")}>
              <IconDrawer type="8" className="colorBlue fs-5 mb-1" />
            </Breadcrumb.Item>
            {/* GENERATING BREADCRUMB FROM THE LIST PROVIDE BY THE CURRENT PAGE */}
            {breadcrumList?.map((item: any, index: any) => (
              <Breadcrumb.Item
                key={index}
                active
                onClick={() => handleGoToScreen(item.link)}
              >
                {item.title}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Container>
      )}
    </>
  );
};

export default Header;

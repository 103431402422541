import axios from "axios";
import { showLoader, hideLoader } from "./loader";
import { TokenSessionStorage } from "./constants";
// import { UserProfileLocalStorage, TokenSessionStorage } from "./Constants";

const instance = axios.create({
  timeout: 5000000000,
});

instance.interceptors.request.use(
  function (config: any) {
    //     Call your API with token
    const accessToken = sessionStorage.getItem(TokenSessionStorage);
    config.headers.Authorization = `Bearer ${accessToken}`;
    return Promise.resolve(config);
    // });
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: any) => {
    if (response.status === 401) {
      //   localStorage.removeItem(UserProfileLocalStorage);
      //   sessionStorage.removeItem(TokenSessionStorage);
    }
    return response;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

export default instance;

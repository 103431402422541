import Header from "../Header";
import Footer from "../Footer";
import { Container, Card, Row, Col } from "react-bootstrap";
import IconDrawer from "../Icons";

const Layout = ({
  children,
  breadcrumList,
  pageTitle,
  IconNo,
  isOptionsHidden,
  isBreadCrumHidden,
}: any) => {
  return (
    <>
      <Header
        breadcrumList={breadcrumList}
        isOptionsHidden={isOptionsHidden}
        isBreadCrumHidden={isBreadCrumHidden}
      />
      <Container className="mt-3">
        {/* ADD A CONTAINER AND A TITLE WITH ICON OR SPECIFIC PAGES */}
        {pageTitle ? (
          <Container>
            <Row className="mb-3">
              <Col className="fs-5">
                <IconDrawer type={IconNo} className="colorBlue mb-1" />
                <span className="mx-2 colorDark">{pageTitle}</span>
              </Col>
            </Row>
            <Card className="p-4">{children}</Card>
          </Container>
        ) : (
          children
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Layout;

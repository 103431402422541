import interceptor from "../Utilities/interceptor";
import { reportHistory, areaFiles, reportFiles } from "../Utilities/baseUrl";
import { showLoader, hideLoader } from "../Utilities/loader";
import { tabKeys } from "../Utilities/constants";
import React from "react";

export const searchReport = async (
  ReportHistoryId: string,
  setTableData: any,
  setHeaderTableData: any,
  setReportData: any
) => {
  try {
    showLoader();
    const { data } = await interceptor.get(reportHistory + ReportHistoryId);
    const files = await interceptor.get(reportFiles + ReportHistoryId, {
      headers: { responseType: "blob" },
    });
    if (!data || !files?.data) throw new Error("Data not found");
    setReportData({ ...data, reportResult: files.data });
    setTableData(files.data);
    setHeaderTableData(data.reportResultTimeLines);
    hideLoader();
  } catch (e) {
    hideLoader();
    console.log(e);
  }
};

export const checkIfExcelLoaded = async (
  url: any,
  prevData: any,
  setIsExcelLoaded: any
) => {
  if (prevData.isExcelGenerated) setIsExcelLoaded(true);
  else {
    setTimeout(async () => {
      const { data } = await interceptor.get(url);
      checkIfExcelLoaded(url, data, setIsExcelLoaded);
    }, 60000);
  }
};

const parseGroupTotal = async (total: any, totalArr: any) => {
  for (let totalIndex = 0; totalIndex < total.length; totalIndex++) {
    totalArr.push({
      Increase: total[totalIndex].increase,
      PercentageIncrease: total[totalIndex].percentageIncrease,
      Title: total[totalIndex].title,
      Total: total[totalIndex].total,
    });
  }
};

const parseBaseLine = async (code: any, currentCode: any) => {
  for (
    let blIndex = 0;
    blIndex < currentCode.BaseLines.IncreaseSinceBaseLine.length;
    blIndex++
  ) {
    const currentBL = currentCode.BaseLines.IncreaseSinceBaseLine[blIndex];
    const BL = {
      increase: currentBL.Increase,
      percentageIncrease: currentBL.PercentageIncrease,
      title: currentBL.Title,
      total: currentBL.Total,
    };
    code.baseLines.increaseSinceBaseLine.push(BL);
  }
};

const parseCode = async (grade: any, currentGrade: any) => {
  for (let codeIndex = 0; codeIndex < currentGrade.Codes.length; codeIndex++) {
    const currentCode = currentGrade.Codes[codeIndex];
    const code: any = {
      code: currentCode.Code,
      baseLines: {
        baselineNumber: currentCode.BaseLines.BaselineNumber,
        increaseSinceBaseLine: [],
      },
    };
    await parseBaseLine(code, currentCode);
    grade.codes.push(code);
  }
};

const parseGradeData = async (school: any, currentSchool: any) => {
  for (
    let gradeIndex = 0;
    gradeIndex < currentSchool.Grades.length;
    gradeIndex++
  ) {
    const currentGrade = currentSchool.Grades[gradeIndex];
    const grade: any = {
      grade: currentGrade.Grade,
      codes: [],
      groupTotals: {
        code: currentGrade.GroupTotals.Code,
        baselineNumber: currentGrade.GroupTotals.BaselineNumber,
        increaseSinceBaseLine: [],
      },
    };
    await parseCode(grade, currentGrade);
    await parseGroupTotal(
      currentGrade.GroupTotals.IncreaseSinceBaseLine,
      grade.groupTotals.increaseSinceBaseLine
    );
    school.grades.push(grade);
  }
};

const parseSchoolData = async (schools: any) => {
  try {
    const schoolArr: any = [];
    if (!Array.isArray(schools)) return schoolArr;
    for (let schoolIndex = 0; schoolIndex < schools.length; schoolIndex++) {
      const currentSchool: any = schools[schoolIndex];
      const school: any = {
        grades: [],
        schoolName: currentSchool.SchoolName,
        groupTotals: {
          code: currentSchool.GroupTotals.Code,
          baselineNumber: currentSchool.GroupTotals.BaselineNumber,
          increaseSinceBaseLine: [],
        },
      };
      await parseGradeData(school, currentSchool);
      await parseGroupTotal(
        currentSchool.GroupTotals.IncreaseSinceBaseLine,
        school.groupTotals.increaseSinceBaseLine
      );
      schoolArr.push(school);
    }
    return schoolArr;
  } catch (error) {
    console.log(error);
  }
};

const parseAreas = (areas: any) => {
  try {
    const areasArr: any = [];
    if (!Array.isArray(areas)) return areasArr;
    for (let areaIndex = 0; areaIndex < areas.length; areaIndex++) {
      const currentArea: any = areas[areaIndex];
      const newArea: any = {
        ...currentArea,
        Area: currentArea.area,
        GroupTotals: {
          Code: currentArea.groupTotals.areasArr,
          BaselineNumber: currentArea.groupTotals.baselineNumber,
          IncreaseSinceBaseLine: [],
        },
        Schools: [],
      };
      currentArea.schools.forEach((school: any) => {
        newArea.Schools.push({ SchoolName: school.schoolName });
      });
      parseGroupTotal(
        currentArea.groupTotals.increaseSinceBaseLine,
        newArea.GroupTotals.IncreaseSinceBaseLine
      );
      areasArr.push(newArea);
    }
    return areasArr;
  } catch (error) {
    console.log(error);
  }
};

const loadSchoolDataAfterDelay = (
  counter: number,
  schoolArr: any,
  areaIndex: any,
  type: any,
  setTableData: any,
  setTotal: any
) => {
  if (counter < schoolArr.length)
    setTimeout(function () {
      setTableData((prev: any) => {
        prev[type][areaIndex].Schools[counter] = schoolArr[counter];
        return JSON.parse(JSON.stringify(prev));
      });
      setTotal((prev: any) => ({
        ...prev,
        [type]: {
          ...prev[type],
          loaded: (prev[type].loaded += 1),
        },
      }));
      loadSchoolDataAfterDelay(
        counter + 1,
        schoolArr,
        areaIndex,
        type,
        setTableData,
        setTotal
      );
    }, 1500);
};
const generateReqArray = (
  data: any,
  setTableData: any,
  setSchoolCount: any
) => {
  const requestArray: any = [];
  tabKeys.forEach((key) => {
    let schoolCount = 0;
    if (!Array.isArray(data[key])) return;
    for (let areaIndex = 0; areaIndex < data[key].length; areaIndex++) {
      requestArray.push({
        enrolmentType: key,
        area: data[key][areaIndex]?.area,
        index: areaIndex,
        setTableData,
      });
      schoolCount += data[key][areaIndex]?.totalSchool;
    }
    setSchoolCount((prev: any) => ({
      ...prev,
      [key]: { ...prev[key], total: schoolCount },
    }));
  });
  return requestArray;
};
const getSchools = async (
  data: any,
  setTableData: any,
  setSchoolCount: any,
  reportHistoryId: any
) => {
  const requestArray: any = generateReqArray(
    data,
    setTableData,
    setSchoolCount
  );
  getSOTSchoolData(0, requestArray, setSchoolCount, reportHistoryId);
};

export const searchReportForSchools = async (
  ReportHistoryId: string,
  setTableData: any,
  setHeaderTableData: any,
  setReportData: any,
  setIsExcelLoaded: any,
  setSchoolCount: any,
  tableData: any
) => {
  try {
    showLoader();
    const { data } = await interceptor.get(reportHistory + ReportHistoryId);
    if (!data || !data?.reportResult) throw new Error("Data not found");
    if (tableData) return;
    setReportData(data);
    let updatedReportResults = {
      Enrolled: data.reportResult.enrolledStundets,
      Preregistered: data.reportResult.preRegStundets,
      TotalEnrolled: data.reportResult.totalEnrolled,
    };
    setTableData({
      Enrolled: parseAreas(data.reportResult.enrolledStundets),
      Preregistered: parseAreas(data.reportResult.preRegStundets),
      TotalEnrolled: parseAreas(data.reportResult.totalEnrolled),
    });
    setHeaderTableData(data.reportResultTimeLines);
    getSchools(
      updatedReportResults,
      setTableData,
      setSchoolCount,
      ReportHistoryId
    );
    checkIfExcelLoaded(reportHistory + ReportHistoryId, data, setIsExcelLoaded);
    hideLoader();
  } catch (e) {
    hideLoader();
    console.log(e);
  }
};

export const getSOTSchoolData = async (
  counter: number,
  requestList: any,
  setSchoolCount: any,
  reportHistoryId: any
) => {
  try {
    if (Array.isArray(requestList) && requestList.length < counter + 1) {
      return;
    }
    const { enrolmentType, area, index, setTableData } = requestList[counter];
    const { data } = await interceptor.get(
      `${areaFiles}?AreaId=${area}&ReportId=${reportHistoryId}&enrolmentType=${enrolmentType}`,
      { headers: { responseType: "blob" } }
    );
    // const newSchoolList = await parseSchoolData(data?.Schools);
    loadSchoolDataAfterDelay(
      0,
      data?.Schools ?? [],
      index,
      enrolmentType,
      setTableData,
      setSchoolCount
    );
    // if (data?.Schools?.length) {
    //   data?.Schools?.forEach((school: any, schoolIndex: number) => {
    //     setTableData((prev: any) => {
    //       prev[enrolmentType][index].Schools[schoolIndex] = school;
    //       return JSON.parse(JSON.stringify(prev));
    //     });
    //   });
    // }
    await getSOTSchoolData(
      counter + 1,
      requestList,
      setSchoolCount,
      reportHistoryId
    );
  } catch (e) {
    console.log(e);
    requestList.push(requestList[counter]);
    await getSOTSchoolData(
      counter + 1,
      requestList,
      setSchoolCount,
      reportHistoryId
    );
  }
};

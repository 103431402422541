import IconDrawer from "../Icons";
import { IgroupTotals } from "../../Modals/GradeOverTime";
import GroupTotalBar from "./GroupTotalBar";

interface IToggleSwitchComponent {
  clickFunction: any;
  name: string;
  level: number;
  iconType: string;
  groupTotals: IgroupTotals;
  isLoaded?: boolean;
  isSchool?: boolean;
}

const ToggleSwitch = ({
  clickFunction,
  name,
  level,
  iconType,
  groupTotals,
  isLoaded,
  isSchool,
}: IToggleSwitchComponent) => {
  return (
    <tr style={{ fontWeight: "bold" }}>
      <td
        onClick={clickFunction}
        className="cursorPointer"
        style={{ paddingLeft: (level - 1) * 30, textAlign: "left" }}
        colSpan={1}
      >
        <IconDrawer type={iconType} className="mx-2" />
        <p
          className="toogleSwitchText"
          style={{ width: 120 - (level - 1) * 30 }}
        >
          {name}
        </p>
      </td>
      {isSchool && !isLoaded ? (
        <td style={{ textAlign: "center" }} colSpan={1}>
          Loading...
        </td>
      ) : (
        <GroupTotalBar groupTotals={groupTotals} />
      )}
    </tr>
  );
};

export default ToggleSwitch;

export const baseUrl = process.env.REACT_APP_baseUrl;
export const generateReport: string =
  "https://jsonplaceholder.typicode.com/users";
export const generateReportStatus: string =
  "https://jsonplaceholder.typicode.com/users"; // is separate than generateReport to track status
export const getReportHistory: string =
  "https://jsonplaceholder.typicode.com/users";
export const reportHistory = baseUrl + "ReportsHistory/";
export const startGenerateReport = baseUrl + "ReportsHistory/";
export const startGenerateReportWithFile =
  baseUrl + "ReportWithManualBaseline/";
export const DownloadExcel = baseUrl + "DownloadExcel/";
export const SOTSchoolData = baseUrl + "SchoolAPI/";
export const reportFiles = baseUrl + "ReportFile/";
export const BaselineFiles = baseUrl + "BaselineFiles/";

export const areaFiles = baseUrl + "AreaFile";

import React, { useState, useEffect } from "react";
import {
  IGradeOverTime,
  IReportResultTimeLines,
} from "../../Modals/GradeOverTime";
import Table from "react-bootstrap/Table";
import * as XLSX from "xlsx";
import CommonFields from "./CommonFields";
import TableHeader from "./Header";
import ToggleSwitch from "./ToggleSwitch";

interface ITableComponent {
  tableMap: any;
  tableData: IGradeOverTime[];
  downloadAsExcel?: any;
  fileName?: string;
  type?: XLSX.BookType;
  tab?: any;
  tableHeaderData?: IReportResultTimeLines[];
  reportType: string;
  startDate: string;
}

const Treeview = ({
  tableMap,
  tableData,
  tableHeaderData,
  reportType,
  startDate,
}: ITableComponent) => {
  const [tableDataMirror, settableDataMirror] = useState<IGradeOverTime[]>([]);
  const [transformedTableData, setTransformedTableData] = useState<any[]>();

  const toggleBaseLines = (gradeIndex: number) => {
    let temp = tableDataMirror;
    if (temp[gradeIndex].Codes.length) {
      temp[gradeIndex].Codes = [];
    } else temp[gradeIndex].Codes = tableData[gradeIndex].Codes;
    settableDataMirror(JSON.parse(JSON.stringify(temp)));
  };

  useEffect(() => {
    if (tableData) {
      settableDataMirror(JSON.parse(JSON.stringify(tableData)));
    }
  }, [tableData]);
  return (
    <Table>
      {tableHeaderData ? (
        <TableHeader
          data={tableHeaderData}
          reportType={reportType}
          headerList={tableMap}
          startDate={startDate}
        />
      ) : (
        <></>
      )}
      <tbody>
        {tableDataMirror.map((gradeData, gradeIndex) =>
          gradeData.Codes.length ? (
            gradeData.Codes.map((codes, codeIndex) => (
              <>
                {codeIndex === 0 ? (
                  <ToggleSwitch
                    clickFunction={() => toggleBaseLines(gradeIndex)}
                    name={"Grade " + gradeData.Grade}
                    level={1}
                    iconType="19"
                    groupTotals={gradeData.GroupTotals}
                  />
                ) : (
                  <></>
                )}
                <CommonFields codes={codes} codeIndex={codeIndex} />
              </>
            ))
          ) : (
            <>
              <ToggleSwitch
                clickFunction={() => toggleBaseLines(gradeIndex)}
                name={"Grade " + gradeData.Grade}
                level={1}
                iconType="20"
                groupTotals={gradeData.GroupTotals}
              />
            </>
          )
        )}
      </tbody>
    </Table>
  );
};

export default Treeview;

export const tableMap = [
  { headerName: () => "Grade", field: "grade", width: 120 },
  { headerName: () => "Code", field: "code", width: 120 },
  { headerName: () => "Number", field: "baseline", width: 120 },
  {
    headerName: (reportType: string) => "Number Since Baseline",
    field: "week1Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Percentage Since Baseline",
    field: "week1Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 1",
    field: "week2Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Percentage Since " + reportType + " 1",
    field: "week2Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 2",
    field: "week3Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Percentage Since " + reportType + " 2",
    field: "week3Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 3",
    field: "week4Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Percentage Since " + reportType + " 3",
    field: "week4Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 4",
    field: "week5Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Percentage Since " + reportType + " 4",
    field: "week5Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 5",
    field: "week6Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Percentage Since " + reportType + " 5",
    field: "week6Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 6",
    field: "week7Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Percentage Since " + reportType + " 6",
    field: "week7Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 7",
    field: "week8Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Percentage Since " + reportType + " 7",
    field: "week8Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 8",
    field: "week9Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Percentage Since " + reportType + " 8",
    field: "week9Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 9",
    field: "week10Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Percentage Since " + reportType + " 9",
    field: "week10Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 10",
    field: "week11Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) =>
      "Percentage Since " + reportType + " 10",
    field: "week11Percentage",
    width: 110,
  },
  {
    headerName: (reportType: string) => "Number Since " + reportType + " 11",
    field: "week12Increase",
    width: 110,
  },
  {
    headerName: (reportType: string) =>
      "Percentage Since " + reportType + " 11",
    field: "week12Percentage",
    width: 110,
  },
];

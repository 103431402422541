import { Card, Col, Container, ProgressBar, Row } from "react-bootstrap";
import Layout from "../../General/Layout";
import { useEffect, useState } from "react";
import { IReportHistoryRow } from "../../Modals/IReportHistoryRow"; // TEMP
import { getReportHistory } from "../../Actions/getReportHistory"; // TEMP
import { Link, useNavigate, useParams } from "react-router-dom";

const ReportStatus = () => {
  const { id } = useParams();
  const [status, setStatus] = useState(0);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  const handleCheckStatus = (data: IReportHistoryRow) => {
    if (
      data.progress === 100 &&
      data.status === "Completed" &&
      data.reportResult
    ) {
      navigate(`/${data.reportType}/${id}`);
    } else {
      setStatus(data.progress);
      setToggle(!toggle);
    }
  };

  useEffect(() => {
    let getReportStatus: any;
    if (id) {
      getReportStatus = setTimeout(() => {
        getReportHistory(id, handleCheckStatus);
      }, 2000);
    }
    return () => clearTimeout(getReportStatus);
  }, [id, toggle]);

  return (
    <Layout isBreadCrumHidden={true}>
      <Container>
        <Row className="mb-3 justify-content-md-center" md="auto">
          <Col className="formWidth">
            <Card>
              <Card.Header>
                <h4>Report Status</h4>
                Grade over Time report is being generated, please wait or visit
                back 'Reports History' page to view the report. An email will be
                sent to you once report is ready.
              </Card.Header>

              <Card.Body className="m-sm-5">
                {status < 100 ? (
                  <ProgressBar
                    animated
                    variant="info"
                    now={status}
                    label={`${status}%`}
                  />
                ) : (
                  <div className="text-center">
                    <Link className="goToHistoryBtn" to="/reporthistory">
                      Go to Report History
                    </Link>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ReportStatus;

import interceptor from "../Utilities/interceptor";
import { DownloadExcel } from "../Utilities/baseUrl";
import { showLoader, hideLoader } from "../Utilities/loader";

export const downloadReport = async (
  ReportId: number,
  fileName = "Report Weekly.xlsx"
) => {
  try {
    showLoader();
    const { data } = await interceptor.get(DownloadExcel + ReportId, {
      responseType: "blob",
    });
    const href = window.URL.createObjectURL(data);
    const anchorElement = document.createElement("a");
    anchorElement.href = href;
    anchorElement.download = fileName;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
    hideLoader();
  } catch (e) {
    hideLoader();
    console.log(e);
  }
};

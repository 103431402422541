import { Button, Card } from "react-bootstrap";
import Layout from "../../General/Layout";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../Config/AzureLogin";

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    // LOGIN FUNCTION
    if (loginType === "redirect") {
      console.log(loginRequest);
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <Layout isBreadCrumHidden={true} isOptionsHidden={true}>
      <Card className="p-5">
        <h3 className="text-center">
          Welcome to Calgary Board of Education's Enrolment report site.
        </h3>
        <Card.Title className="text-center">
          <Button onClick={() => handleLogin("redirect")}>Sign In</Button>
        </Card.Title>
      </Card>
    </Layout>
  );
};

export default Login;
